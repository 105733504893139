function importAll(r) {
  let images = {};
  r.keys().map(item => images[item.replace('./', '').replace('.png', '')] = r(item).default);
  return images;
}

const summerImages = importAll(require.context('../../images/hero/summer', false, /\.(png|jpe?g|svg)$/));
export const summerImageData = [
  {
    layer: 1,
    x: 0,
    y: 0,
    width: 1920,
    height: 732,
    image: summerImages['backdrop'],
    name: 'backdrop'
  },
  {
    layer: 2,
    x: 1332.3,
    y: 165.95,
    width: 231,
    height: 123.5,
    image: summerImages['plane'],
    name: 'plane'
  },
  {
    layer: 3,
    x: 453.8,
    y: 165.45,
    width: 766.5,
    height: 343.5,
    image: summerImages['buildings-left-2'],
    name: 'buildings-left-2'
  },
  {
    layer: 4,
    x: 0,
    y: 0,
    width: 1564,
    height: 1079.5,
    image: summerImages['buildings-left'],
    name: 'buildings-left'
  },
  {
    layer: 6,
    x: 0,
    y: 726.45,
    width: 1920,
    height: 353,
    image: summerImages['street'],
    name: 'street'
  },
  {
    layer: 4,
    x: 1234.3,
    y: 319.45,
    width: 686,
    height: 761,
    image: summerImages['buildings-right'],
    name: 'buildings-right'
  },
  {
    layer: 4,
    x: 1548.8,
    y: 601.45,
    width: 273,
    height: 125,
    image: summerImages['skyline-back'],
    name: 'skyline-back'
  },
  {
    layer: 5,
    x: 0,
    y: 728.45,
    width: 1688,
    height: 150.5,
    image: summerImages['sidewalk'],
    name: 'sidewalk'
  },
  {
    layer: 5,
    x: 584.8,
    y: 653.95,
    width: 232,
    height: 157.5,
    image: summerImages['couple'],
    name: 'couple'
  },
  {
    layer: 5,
    x: 1586.8,
    y: 681.95,
    width: 51,
    height: 50.5,
    image: summerImages['tree-1'],
    name: 'tree-1'
  },
  {
    layer: 5,
    x: 1433.3,
    y: 633.95,
    width: 92.5,
    height: 108,
    image: summerImages['tree-2'],
    name: 'tree-2'
  },
  {
    layer: 5,
    x: 1330.8,
    y: 653.95,
    width: 336.5,
    height: 181,
    image: summerImages['tram'],
    name: 'tram'
  },
  {
    layer: 3,
    x: 949.8,
    y: 518.95,
    width: 211,
    height: 251,
    image: summerImages['tree-3'],
    name: 'tree-3'
  },
  {
    layer: 6,
    x: 1275.8,
    y: 676.8,
    width: 222.5,
    height: 269,
    image: summerImages['biker'],
    name: 'biker'
  },
  {
    layer: 6,
    x: 684.78,
    y: 215 ,
    width: 1179,
    height: 865,
    image: summerImages['car'],
    name: 'car'
  },
  {
    layer: 7,
    x: 1495.3,
    y: 705,
    width: 358,
    height: 375.5,
    image: summerImages['girl'],
    name: 'girl'
  },
  {
    layer: 7,
    x: 622,
    y: 683,
    width: 286,
    height: 396,
    image: summerImages['man'],
    name: 'man'
  },
]

const winterImages = importAll(require.context('../../images/hero/winter', false, /\.(png|jpe?g|svg)$/));
export const winterImageData = [
  {
    layer: 1,
    x: 0,
    y: 0,
    width: 1920,
    height: 727.071,
    image: winterImages['backdrop'],
    name: 'backdrop'
  },
  {
    layer: 2,
    x: 1320.224,
    y: 152.856,
    width: 270.527,
    height: 143.014,
    image: winterImages['plane'],
    name: 'plane'
  },
  {
    layer: 3,
    x: 462.139,
    y: 172.858,
    width: 749.574,
    height: 329.532,
    image: winterImages['right-building-2'],
    name: 'right-building-2'
  },
  {
    layer: 4,
    x: 0,
    y: 16.843,
    width: 1553.652,
    height: 1063.104,
    image: winterImages['left-building'],
    name: 'left-building'
  },
  {
    layer: 6,
    x: 0,
    y: 725.912,
    width: 1920,
    height: 354.035,
    image: winterImages['street'],
    name: 'street'
  },
  {
    layer: 4,
    x: 1226.214,
    y: 350.375,
    width: 694.068,
    height: 730.072,
    image: winterImages['right-building'],
    name: 'right-building'
  },
  {
    layer: 4,
    x: 1549.246,
    y: 602.4,
    width: 272.527,
    height: 124.012,
    image: winterImages['skyline-back'],
    name: 'skyline-back'
  },
  {
    layer: 5,
    x: 0,
    y: 726.412,
    width: 1685.665,
    height: 147.514,
    image: winterImages['sidewalk'],
    name: 'sidewalk'
  },
  {
    layer: 5,
    x: 587.652,
    y: 653.405,
    width: 234.023,
    height: 156.015,
    image: winterImages['couple'],
    name: 'couple'
  },
  {
    layer: 5,
    x: 1586.75,
    y: 681.408,
    width: 51.005,
    height: 50.005,
    image: winterImages['tree-1'],
    name: 'tree-1'
  },
  {
    layer: 5,
    x: 1434.235,
    y: 632.903,
    width: 91.509,
    height: 109.511,
    image: winterImages['tree-2'],
    name: 'tree-2'
  },
  {
    layer: 5,
    x: 1425.234,
    y: 645.404,
    width: 278.527,
    height: 188.018,
    image: winterImages['tram'],
    name: 'tram'
  },
  {
    layer: 3,
    x: 949.687,
    y: 512.391,
    width: 214.021,
    height: 255.025,
    image: winterImages['tree-3'],
    name: 'tree-3'
  },
  {
    layer: 6,
    x: 1385.23,
    y: 699.91,
    width: 100.01,
    height: 230.523,
    image: winterImages['bike'],
    name: 'bike'
  },
  {
    layer: 6,
    x: 842.177,
    y: 363.377,
    width: 843.583,
    height: 716.57,
    image: winterImages['car'],
    name: 'car'
  },
  {
    layer: 7,
    x: 1491.74,
    y: 715.964,
    width: 365.036,
    height: 365.036,
    image: winterImages['girl'],
    name: 'girl'
  },
  {
    layer: 7,
    x: 626.873,
    y: 683.961,
    width: 289.528,
    height: 396.039,
    image: winterImages['man'],
    name: 'man'
  },
]
