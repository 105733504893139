
import './style.scss';
import {debounce} from "lodash/function";
import {imageLazyLoading} from '../../scripts/functions/imageLazyLoading';
import {animations} from '../../scripts/general/animations';
import {gsap} from "gsap";

/**
 *
 * @param header {HTMLElement}
 * @returns {Promise<void>}
 */
export default async (header) => {

  const burgerMenu = header.querySelector('.burger-menu');
  const menuLinks = header.querySelector('.header-links');
  const menuLinksHeaders = menuLinks.querySelectorAll('.header-link');
  const minDesktopMedia = window.matchMedia('(min-width: 992px)');
  const menuLinksTl = gsap.timeline({
    delay: 0.5, paused: true, defaults: {
      duration: 0.3
    }
  });
  const setMobileTimeline = () => menuLinksTl
    .progress(0)
    .clear(true)
    .fromTo(menuLinks, {xPercent: 100, x: 0}, {
      x: 0,
      xPercent: 0,
      immediateRender: true,
    })
    .fromTo(menuLinksHeaders, {
      y: 30,
      autoAlpha: 0,
    }, {
      y: 0,
      autoAlpha: 1,
      stagger: .05,
      duration: 0.2
    })

  const resizeHandler = debounce(() => {
      if (minDesktopMedia.matches) {
        burgerMenu.classList.remove('burger-menu-active');
        menuLinks.classList.remove('header-links-active');
        header.classList.remove('header-active');
        burgerTl.reverse();
        menuLinksTl.reverse();
        gsap.set([menuLinks, ...menuLinksHeaders], {clearProps: 'all'})
      } else {
        setMobileTimeline()
      }
    },
    500)

  resizeHandler()
  minDesktopMedia.addEventListener('change', resizeHandler);

  if (!burgerMenu) return;
  const burgerTl = gsap.timeline({paused: true});
  const burgerSpans = burgerMenu.querySelectorAll('span');
  gsap.set(burgerSpans, {transformOrigin: 'center'});
  burgerTl
    .to(burgerSpans, {
      y: gsap.utils.wrap([`0.6rem`, 0, `-0.6rem`]),
      duration: 0.25,
    })
    .set(burgerSpans, {autoAlpha: gsap.utils.wrap([1, 0, 1])})
    .to(burgerSpans, {rotation: gsap.utils.wrap([45, 0, -45])})
    .set(burgerSpans, {rotation: gsap.utils.wrap([45, 0, 135])});
  burgerMenu.addEventListener('click', function () {
    if (burgerMenu.classList.contains('burger-menu-active')) {
      // allowPageScroll()
      burgerMenu.classList.remove('burger-menu-active');
      menuLinks.classList.remove('header-links-active');
      header.classList.remove('header-active');
      burgerTl.reverse();
      menuLinksTl.reverse();
    } else {
      burgerMenu.classList.add('burger-menu-active');
      menuLinks.classList.add('header-links-active');
      header.classList.add('header-active');
      burgerTl.play();
      menuLinksTl.play();
      // preventPageScroll();
      //
    }

  });

  // logo animation

  // const logoAnimation = header.querySelectorAll(".main-logo svg path,.main-logo svg circle")
  //
  // gsap.from(logoAnimation, {
  //     scaleX: 0,
  //     transformOrigin: "left",
  //     stagger: .2,
  //     ease: "back",
  //     repeat: -1,
  //     yoyo: true,
  //     repeatDelay: 4,
  //   })


  animations(header);
  imageLazyLoading(header);
};

