import './style.scss';
import {debounce} from "lodash";
import Parallax from "parallax-js";
import {imageLazyLoading} from "../../scripts/functions/imageLazyLoading";
import {animations} from "../../scripts/general/animations";
import gsap from "gsap";
import {ScrollTrigger} from "gsap/ScrollTrigger";
import {SplitText} from "gsap/SplitText";
import {summerImageData, winterImageData} from "./data";

gsap.registerPlugin(ScrollTrigger, SplitText)

let images;

const getImages = (season, callback) => {
  images = (season === 'winter' ? winterImageData : summerImageData).map((img) => {
    const imageElement = new Image;
    imageElement.onload = callback;
    imageElement.src = img.image;
    return {...img, image: imageElement}
  })
}
const drawCanvas = (_canvas, points) => {
  if (window.scrollY > window.innerHeight) return;
  const w = _canvas.width;
  const h = _canvas.height;
  const ctx = _canvas.getContext('2d');
  ctx.clearRect(0, 0, w, h);
  const ratio = w / 1920;
  images.map(({
                x,
                y,
                width,
                height,
                layer,
                image
              }) => ctx.drawImage(image, x * ratio, y * ratio +
    0.015 * h * (8 - layer * window.scrollY / window.innerHeight), width * ratio, height * ratio));
  gsap.set(points, {
    y: (i,) => {

      return 0.015 * h * (8 - +points[i].dataset.layer * (window.scrollY / window.innerHeight)) / window.devicePixelRatio;
    },
    yPercent: -50,
    xPercent: -50
  })
}

const initCanvas = function (_canvas, points) {
  _canvas.width = ~~window.innerWidth * window.devicePixelRatio;
  _canvas.height = _canvas.width * 9 / 16;
  drawCanvas(_canvas, points)
};

/**
 * @param block {HTMLElement}
 * @returns {Promise<void>}
 */
const heroBlock = async (block) => {
    const canvasWrapper = block.querySelector('.background-wrapper')
    const canvas = canvasWrapper.querySelector('canvas')
    const {season} = canvasWrapper.dataset
    const clipPath = block.querySelector('#hero-clip-path path');
    const points = document.getElementsByClassName('scene2');

    getImages(season, () => drawCanvas(canvas, points));
    initCanvas(canvas, points, false);

    window.addEventListener('resize', debounce(() => initCanvas(canvas, points), 500));
    window.addEventListener('scroll', () => drawCanvas(canvas, points));

    gsap.to(clipPath, {
      onUpdate: () => {
        block.style.clipPath = 'none';
        block.offsetWidth
        block.style.clipPath = 'url(#hero-clip-path)';
      },
      scrollTrigger: {
        trigger: block,
        start: 'top top',
        end: 'bottom top',
        scrub: .2,
        onToggle({isActive}) {
          gsap.set(block, {willChange: isActive ? 'clip-path' : 'auto'})
        }
      },
      attr: () => ({d: () => `M0,0H1V1Q.5,${1 - 0.1 * window.innerWidth / window.innerHeight} 0,1Z`}),
    })
    const mediaDesktop = window.matchMedia('(min-width: 600px)');
    if (mediaDesktop.matches) {
      for (let i = 0; i < points.length; i++) {
        new Parallax(points[i], {
          hoverOnly: true,
          pointerEvents: true,
          relativeInput: true
        })
      }
    }


    animations(block);
    imageLazyLoading(block);
  }
;

export default heroBlock;

