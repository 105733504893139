export function headerSticky(container = document) {
  // header sticky
  const hero = container.querySelector('.hero_block');
  const header = document.querySelector('header');
  if (!header) {
    return;
  }
  window.addEventListener('scroll', function () {
    if (hero) {
      header.classList.toggle('has-form', window.scrollY >= hero.offsetHeight - header.offsetHeight);
    }
      header.classList.toggle('sticky', window.scrollY >= 20);
  });

}
