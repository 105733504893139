import {gsap} from 'gsap';
import {ScrollTrigger} from 'gsap/ScrollTrigger';
gsap.registerPlugin(ScrollTrigger);

export function getHeightOfViewPort() {
// Then we set the value in the --vh custom property to the root of the document
  const setHeight = () => {
    const oldVh = document.documentElement.style.getPropertyValue('--vh')
    const newVh = `${window.innerHeight * 0.01}px`
    document.documentElement.style.setProperty('--vh', newVh);
    oldVh !== newVh && ScrollTrigger.update()
  }

  setHeight();

  window.addEventListener('resize', setHeight);
}
