import './styles/style.scss';
import {gsap} from 'gsap';
import ScrollTrigger from 'gsap/ScrollTrigger';
import header from './blocks/header_block';
import hero from './blocks/hero_block';
import {initBlocks} from './blocks';
import {getHeightOfViewPort} from './scripts/functions/getHeightOfViewPort';
import {debouncedScrollTriggerRefresh} from "./scripts/functions/gsapDebouncedFunctions";
import {scrollToHash} from './scripts/general/scroll-to-hash';
import {headerSticky} from './scripts/general/header-sticky';

const reInvokableFunction = async (container = document) => {
  container.querySelector('header') && await header(container.querySelector('header'));
  container.querySelector('.hero_block') && await hero(container.querySelector('.hero_block'));

  getHeightOfViewPort(container);

  await initBlocks(container);

  scrollToHash(container);
  headerSticky(container);

  debouncedScrollTriggerRefresh();
};
let loaded = false;

async function onLoad() {
  gsap.config({
    nullTargetWarn: false,
  });

  if (document.readyState === 'complete' && !loaded) {
    loaded = true;
    document.body.classList.add('loaded');
    gsap.registerPlugin(ScrollTrigger);

    await reInvokableFunction();

  }

}

onLoad();

document.onreadystatechange = function () {
  onLoad();
  setTimeout(() => window.scrollTo(0, 0))
};
